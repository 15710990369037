@use "../0-settings/colors";
@use "../1-tools/mixins";

/*------------------------------------*\
  #GALLERY
\*------------------------------------*/

/**
 * Wrapper to the picture gallery items.
 */
.c-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: .5rem .2rem;
  list-style-type: none;
}

  /**
   * A gallery item, most likely a photo wrapped inside a link.
   *
   * 1. Dimension are depending on current font-size and viewport width.
   * 2. Crops the potential exceeding content to keep a squared item.
   * 3. Gets rid of default link styling.
   */
  .c-gallery__item {
    position: relative;
    flex-basis: calc(7em + 5vw); /* [1] */
    height: calc(7em + 5vw); /* [1] */
    overflow-y: hidden; /* [2] */
    margin: .75rem;
    transition: all .2s;
    border: none; /* [3] */

    @include mixins.hocus {
      border: none; /* [3] */
      outline: .25rem solid colors.$focus-color;
      transform: scale(1.05);
    }

  }

    /**
     * Media displayed in the gallery, most likely a photo.
     */
    .c-gallery__media {
      width: 100%;
      height: auto;
    }


    /**
     * Wrapper to information such as the title and the hint.
     *
     * 1. The wrapper is spread all over the gallery item.
     * 2. It is not displayed until the gallery is either hover or focused.
     */
    .c-gallery__item-details {
      /* [1] */
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: none; /* [2] */
      text-align: center;
      color: colors.$color-white;
    }


    /**
     * Wrapper to information such as the title and the hint once the gallery
     * item is either focused or hovered.
     *
     * The content is positionied to the middle of the gallery item.
     */
    .c-gallery__item:focus .c-gallery__item-details,
    .c-gallery__item:hover .c-gallery__item-details {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    /**
     * The title of the gallery item.
     */
    .c-gallery__item-details__title {
      display: block;
      padding: .5em;
      background-color: colors.$primary-color;
    }
