@use "../1-tools/mixins";

.c-social-buttons {
  @include mixins.nav;

  svg > * {
    fill: currentColor;
  }
}

.c-social-buttons--left {
  ul {
    justify-content: flex-start;
  }
}

.c-social-buttons--color {

  #instagram {
    color: #000;
  }

  #facebook {
    color: #3b5998;
  }

  #pinterest {
    color: #e60023;
  }

}
