$color-gold: #ae8913;
$color-orange: #A26810;
$color-blue: #2f70b4;
$color-blue-dark: #2A4ACB;
$color-yellow: #ffbf47;
$color-green: #00857E;
$color-black: #333;
$color-white: #fff;
$color-gray: #757575;
$primary-color: $color-gold;
$link-underline-color: $color-orange;
$focus-color: $color-yellow;
