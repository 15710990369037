@use "../0-settings/colors";
@use "../1-tools/mixins";

.c-site-nav {

  @include mixins.nav;

  li:first-child {
    width: 100%;
    text-align: center;

    position: relative;

    &:before {
      background-color: colors.$primary-color;
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      transform: translate(-.5rem,calc(-50% - .5rem));
      z-index: -1;
    }
  }

}

.c-site-nav__logo {

  /* Remove link underline */
  border-bottom: 0;

  & > svg {
    height: 40vmax;
    max-height: 300px;
    width: auto;
  }
}
