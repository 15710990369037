@use "../0-settings/core";

dl {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  overflow: visible;
  margin-bottom: core.$baseline;
}

dt {
  flex: 0 0 40%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
}

dd {
  margin-left: auto;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 0 0 60%
}
