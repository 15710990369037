@use "../0-settings/core";

header,
main {
  margin-bottom: core.$baseline;
}

main {
  // Push the footer to stick to bottom.
  min-height: 55vh;
}
