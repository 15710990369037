@use "../0-settings/colors";
@use "../0-settings/core";

.c-workshop-card {
  border: .125em solid colors.$primary-color;
  border-left-width: .5em;
  padding: core.$spacing-medium;
  margin-bottom: core.$baseline;
}

.c-workshop-card--past {
  border-color: colors.$color-gray;
}

  .c-workshop-card__heading {
    margin-top : 0;
  }

  .c-workshop-card__cta {
    text-align: center;
  }
