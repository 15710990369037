@use "../0-settings/colors";
@use "../1-tools/mixins";

/*------------------------------------*\
  #CATEGORIES
\*------------------------------------*/

/**
 * Navigation for reaching a specific category page.
 *
 */
.c-categories {
}

  /**
   * The list of links to category page. Usually an unordered list.
   */
  .c-categories__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    list-style-type: none;
    padding: 0;
  }

    /**
     * The category item.
     *
     * 1. Dimensions are depending on current font-size and viewport width.
     */
    .c-categories__item {
      width: calc(5em + 5vw); /* [1] */
      margin: .3em;
      text-align: center;
    }

      /**
       * The link to the category.
       *
       * 1. Drops default, so that we can customize.
       * 2. Sets the color here so that it can be used as currentColor for hocus
       *    states border, background-color...
       */
      .c-categories__link {
        border: 0;

        @include mixins.hocus {
          outline: 0;
          background: none;
        }

      }

        /**
         * Image illustrating the category.
         *
         * 1. This avoids having a jump when the border is shown on hocus.
         */
        .c-categories__image {
          max-width: 100%;
          border-radius: 50%;
          border-width: .25em;
          border-style: solid;
          border-color: transparent; /* [1] */

          .c-categories__link:hover &,
          .c-categories__link:focus & {
            transition: border-color .3s;
          }

          .c-categories__link:hover & {
            border-color: colors.$primary-color;
          }

          .c-categories__link:focus & {
            border-color: colors.$focus-color;
          }

        }

        /**
         * The category title.
         */
        .c-categories__title {

          .c-categories__link &,
          .c-categories__link:hover & {
            border-bottom: .1em solid colors.$primary-color;
          }

          .c-categories__link:hover & {
            border-bottom-width: .15em;
          }

          .c-categories__link:focus & {
            outline: .125rem solid colors.$focus-color;
            background-color: colors.$focus-color;
          }

        }

