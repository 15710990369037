@use "../0-settings/colors";

.c-ui-link,
.c-ui-link:visited {
  border-bottom: 0;
  background: none;
}
.c-ui-link:focus {
  outline: 0.125rem solid colors.$color-yellow;
  outline-offset: 0.125rem;
  background: none;
}
