@use "../0-settings/core";

.o-big-picture {
  width: 95vmin;
  margin: 0 auto;
}

  .o-big-picture__image {
    max-width: 100%;
  }

  .o-big-picture__date {
    display: block;
    margin-bottom: core.$baseline;
  }
