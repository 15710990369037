@use "../0-settings/colors";

a,
a:visited {
  font-weight: bold;
  color: currentColor;
  text-decoration: none;
  border-bottom: .1em solid colors.$link-underline-color;
}
a:hover {
  border-bottom-width: .15em;
}
a:focus {
  outline: 0.125rem solid colors.$color-yellow;
  background-color: colors.$color-yellow;
}

